import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  TextField,
  CircularProgress,
  ThemeProvider,
  createTheme,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as EmailValidator from "email-validator";

import "./career.styles.scss";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0c51ca",
    },
  },
  typography: {
    fontFamily: ' "Poppins", sans-serif',
  },
});

const Career = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  const [showLoading, setShowLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [nameError, setNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [mobileError, setMobileError] = useState();
  const [subjectError, setSubjectError] = useState();
  const [messageError, setMessageError] = useState();

  const send = () => {
    if (name == null || name === "") {
      setNameError("Name is required!");
      setEmailError(null);
      setMobileError(null);
      setSubjectError(null);
      setMessageError(null);
      return;
    } else if (email == null || email === "") {
      setNameError(null);
      setEmailError("Email is required!");
      setMobileError(null);
      setSubjectError(null);
      setMessageError(null);
      return;
    } else if (mobile == null || mobile === "") {
      setNameError(null);
      setEmailError(null);
      setMobileError("Mobile is required!");
      setSubjectError(null);
      setMessageError(null);
      return;
    } else if (subject == null || subject === "") {
      setNameError(null);
      setEmailError(null);
      setMobileError(null);
      setSubjectError("Subject is required!");
      setMessageError(null);
      return;
    } else if (message == null || email === "") {
      setNameError(null);
      setEmailError(null);
      setMobileError(null);
      setSubjectError(null);
      setMessageError("Message cannot be null!");
      return;
    } else if (!EmailValidator.validate(email)) {
      setNameError(null);
      setEmailError("Email is not valid!");
      setMobileError(null);
      setSubjectError(null);
      setMessageError(null);
      return;
    } else if (mobile.length !== 10) {
      setNameError(null);
      setEmailError(null);
      setMobileError("Mobile no. should of 10 digits.");
      setSubjectError(null);
      setMessageError(null);
      return;
    } else {
      // Send email
      setNameError(null);
      setEmailError(null);
      setMobileError(null);
      setSubjectError(null);
      setMessageError(null);
      setShowLoading(true);

      axios
        .post(
          "https://us-central1-autoloop-8a54c.cloudfunctions.net/app/query",
          {
            type: "career/contact us",
            name: name,
            email: email,
            mobile: mobile,
            subject: subject,
            message: message,
          }
        )
        .then((_) => {
          setName("");
          setEmail("");
          setMobile("");
          setSubject("");
          setMessage("");
          setNameError(null);
          setEmailError(null);
          setMobileError(null);
          setSubjectError(null);
          setMessageError(null);
          setShowLoading(false);
          setShowDialog(true);
        });
    }
  };

  return (
    <div className="career">
      <div className="web">
        <Dialog disableEscapeKeyDown open={showDialog}>
          <DialogTitle
            style={{
              fontFamily: '"Poppins", sans-serif',
              fontSize: "1rem",
            }}
          >
            Query Received!
          </DialogTitle>
          <DialogContent>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontFamily: '"Poppins", sans-serif',
                fontSize: "0.9rem",
                width: "250px",
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
            >
              <ThemeProvider theme={theme}>
                <p>We will get back to you ASAP!</p>
              </ThemeProvider>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                fontFamily: '"Poppins", sans-serif',
                fontSize: "0.8rem",
                color: "#0c51ca",
              }}
              onClick={() => setShowDialog(false)}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="selected" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="link" to="/business">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="link" to="/csr-sustainability">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="link" to="/innovation-rnd">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <Grid container spacing={0} justify="center" className="content">
          {/* Left Section */}
          <Grid item xs={5}>
            <div className="left-content">
              <div className="head">Say Hello</div>
              <a href="mailto:info@biomye.com">info@biomye.com</a>
              <div className="divider"></div>
              <div className="head">Mailing Address</div>
              <div>
                Abiomye Industries Private Limited
                <br />
                +91-0120-4138854
                <br />
                Corporate Office:
                <br />
                B-53/D, Ground Floor,
                <br />
                Dilshad Colony, New Delhi.
              </div>
            </div>
          </Grid>
          {/* Right Section */}
          <Grid item xs={7}>
            <div className="content">
              <ThemeProvider theme={theme}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="Name"
                  className="input-single"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  error={!(nameError == null)}
                  helperText={nameError}
                />
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="Email"
                  className="input-single"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  error={!(emailError == null)}
                  helperText={emailError}
                />
                <TextField
                  fullWidth
                  required
                  inputMode="numeric"
                  size="small"
                  label="Mobile Number"
                  className="input-single"
                  value={mobile}
                  onChange={(event) => setMobile(event.target.value)}
                  error={!(mobileError == null)}
                  helperText={mobileError}
                />
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="Subject"
                  className="input-single"
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                  error={!(subjectError == null)}
                  helperText={subjectError}
                />
                <TextField
                  fullWidth
                  required
                  multiline
                  rows={5}
                  size="small"
                  label="Message"
                  className="input-single"
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  error={!(messageError == null)}
                  helperText={messageError}
                />
              </ThemeProvider>
              <Button onClick={send} className="send">
                {showLoading ? (
                  <CircularProgress className="loader" size={"1.4rem"} />
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">Careers / Contact Us</div>
          <div className="head">Say Hello</div>
          <a href="mailto:info@biomye.com">info@biomye.com</a>
          <div className="head">Mailing Address</div>
          <div>
            Abiomye Industries Private Limited
            <br />
            +91-0120-4138854
            <br />
            Corporate Office:
            <br />
            B-53/D, Ground Floor,
            <br />
            Dilshad Colony, New Delhi.
          </div>
          <div className="feilds-wrapper">
            <ThemeProvider theme={theme}>
              <TextField
                fullWidth
                required
                size="small"
                label="Name"
                className="input-single"
                value={name}
                onChange={(event) => setName(event.target.value)}
                error={!(nameError == null)}
                helperText={nameError}
              />
              <TextField
                fullWidth
                required
                size="small"
                label="Email"
                className="input-single"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={!(emailError == null)}
                helperText={emailError}
              />
              <TextField
                fullWidth
                required
                inputMode="numeric"
                size="small"
                label="Mobile Number"
                className="input-single"
                value={mobile}
                onChange={(event) => setMobile(event.target.value)}
                error={!(mobileError == null)}
                helperText={mobileError}
              />
              <TextField
                fullWidth
                required
                size="small"
                label="Subject"
                className="input-single"
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
                error={!(subjectError == null)}
                helperText={subjectError}
              />
              <TextField
                fullWidth
                required
                multiline
                rows={5}
                size="small"
                label="Message"
                className="input-single"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                error={!(messageError == null)}
                helperText={messageError}
              />
            </ThemeProvider>
            <Button onClick={send} className="send">
              {showLoading ? (
                <CircularProgress className="loader" size={"1.4rem"} />
              ) : (
                "Send"
              )}
            </Button>
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
