import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import "./biomye.styles.scss";

const Biomye = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="biomye">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="link" to="/csr-sustainability">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="link" to="/innovation-rnd">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        <Grid container spacing={0} justify="center">
          {/* Left Section */}
          <Grid item xs={4} className="left-item">
            <img
              className="app-image"
              alt="mockup"
              src={process.env.PUBLIC_URL + "/assets/biomye.png"}
            />
          </Grid>
          {/* Right Section */}
          <Grid item xs={8} className="right-item">
            <div className="right-content">
              <div className="title">Biomye.com</div>
              <div className="body">
                In today’s world there are a lot of e-commerce websites to
                choose from most of the websites work on a simple model of
                providing the customers the product, the sellers have fullest
                possibility to upload any product of your choice which is open
                to the consumers regardless of their quality which remains a
                secret for the customer until the product arrives at the
                doorstep of the consumer. A new change Is being brought by
                Biomye’s team, Wherein product quality is guaranteed by the
                company by conducting various test on the product.
                <br />
                <br />
                The products that are sourced upon the website do have the
                highest quality standards of the market under which the products
                must be exported to European countries where in the product
                registration is a must when it comes to healthcare products.
                Regardless fact about the products sold on the website is that
                it is being sold or bought internationally which recognised the
                product to be a international quality product for the Indian
                consumer.
                <br />
                <br />
                Our mantra of the company is to provide the Consumer with the
                highest quality product available in the market today, which The
                team of the website would not hesitate to use in their own
                personal space.
                <br />
                <br />
                Biomye’s team is full of passion and positivity, when it comes
                to testing, searching and adapting a product for the market. The
                team consists of energetic people young at heart regardless of
                their age, gender promoting equality at the workplace. We at
                Biomye ensure the consumer with quality. Which is embedded in
                the core value of the team.
              </div>
            </div>
          </Grid>
        </Grid>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">Biomye.com</div>
          <div className="wrapper">
            <img
              className="app-image"
              alt="mockup"
              src={process.env.PUBLIC_URL + "/assets/biomye.png"}
            />
          </div>
          <div className="body">
            In today’s world there are a lot of e-commerce websites to choose
            from most of the websites work on a simple model of providing the
            customers the product, the sellers have fullest possibility to
            upload any product of your choice which is open to the consumers
            regardless of their quality which remains a secret for the customer
            until the product arrives at the doorstep of the consumer. A new
            change Is being brought by Biomye’s team, Wherein product quality is
            guaranteed by the company by conducting various test on the product.
            <br />
            <br />
            The products that are sourced upon the website do have the highest
            quality standards of the market under which the products must be
            exported to European countries where in the product registration is
            a must when it comes to healthcare products. Regardless fact about
            the products sold on the website is that it is being sold or bought
            internationally which recognised the product to be a international
            quality product for the Indian consumer.
            <br />
            <br />
            Our mantra of the company is to provide the Consumer with the
            highest quality product available in the market today, which The
            team of the website would not hesitate to use in their own personal
            space.
            <br />
            <br />
            Biomye’s team is full of passion and positivity, when it comes to
            testing, searching and adapting a product for the market. The team
            consists of energetic people young at heart regardless of their age,
            gender promoting equality at the workplace. We at Biomye ensure the
            consumer with quality. Which is embedded in the core value of the
            team.
          </div>
        </div>
        {/* Footer */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Biomye;
