import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Paper,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import "./textiles.styles.scss";

const Textiles = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  let data = [
    {
      name: "INRA",
      image: process.env.PUBLIC_URL + "/assets/textile.png",
      content:
        "Our Textile professional planning & production team has an advanced manufacturing knowledge and extensive experience especially in fashion products, such as garment, washed and garment-dyed items, printed & appliqued garments, as well as basic garments. All production steps from cutting to sewing, packaging and controlling, are handled by our well-qualified employees which keeps our costs low. Besides, we manufacture garments in India with low production costs which lead to a lower price for our customers. In addition to that our satisfied reference customers prove us right, that we deliver high-quality for a reasonable price. We use the latest technology throughout our production facilities to produce, control and deliver high quality products to our customers. We’re specialized in manufacturing ladies clothes and design-lead sourcing and planning of apparel and goods, that reflect the latest trends and consumer needs.",
    },
  ];

  return (
    <div className="textiles">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="link" to="/csr-sustainability">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="link" to="/innovation-rnd">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div>
            {data.map((c) => (
              <Paper className="item">
                <Grid container spacing={0} justify="center">
                  <Grid item xs={3} className="left-item">
                    <img className="prod-img" alt="product" src={c.image} />
                  </Grid>
                  <Grid item xs={9} className="right-item">
                    <div className="prod-text">
                      <div className="title">{c.name}</div>
                      <div className="content-text">{c.content}</div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">Textiles</div>
          <div>
            {data.map((c) => (
              <div className="item">
                <img className="prod-img" alt="product" src={c.image} />
                <div className="title">{c.name}</div>
                <div className="content-text">{c.content}</div>
              </div>
            ))}
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Textiles;
