import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import "./business.styles.scss";

const Business = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  let businessData = [
    {
      name: "Autoloop",
      image: process.env.PUBLIC_URL + "/assets/autoloop.png",
      path: "/business/autoloop",
    },
    {
      name: "Ayurveda & Traditional Medicines",
      image: process.env.PUBLIC_URL + "/assets/ayurveda.png",
      path: "/business/ayurveda-&-traditional",
    },
    {
      name: "Biomye",
      image: process.env.PUBLIC_URL + "/assets/biomye.png",
      path: "/business/biomye",
    },
    {
      name: "Contract Manufacturing",
      image: process.env.PUBLIC_URL + "/assets/contract.png",
      path: "/business/manufacturing",
    },
    {
      name: "FMCG",
      image: process.env.PUBLIC_URL + "/assets/fmcg.png",
      path: "/business/fmcg",
    },
    {
      name: "Health Care",
      image: process.env.PUBLIC_URL + "/assets/healthcare.png",
      path: "/business/healthcare",
    },
    {
      name: "Condoms",
      image: process.env.PUBLIC_URL + "/assets/medicinal.png",
      path: "/business/medicinal-devices",
    },
    {
      name: "OTC Neutraceuticals",
      image: process.env.PUBLIC_URL + "/assets/otc.png",
      path: "/business/otc-neutraceuticals",
    },
    {
      name: "Textiles",
      image: process.env.PUBLIC_URL + "/assets/textile.png",
      path: "/business/textiles",
    },
  ];

  return (
    <div className="business">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="link" to="/csr-sustainability">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="link" to="/innovation-rnd">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            {businessData.map((b) => (
              <Grid item className="item" xs={3}>
                <div>
                  <Link to={b.path}>
                    <img className="prod-img" alt="product" src={b.image} />
                  </Link>
                  <div className="prod-text">{b.name}</div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            {businessData.map((b) => (
              <Grid item className="item" xs={5}>
                <div>
                  <Link to={b.path}>
                    <img className="prod-img" alt="product" src={b.image} />
                  </Link>
                  <div className="prod-text">{b.name}</div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
