import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import "./rnd.styles.scss";

const RnD = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="rnd">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="link" to="/business">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="link" to="/csr-sustainability">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <img
            alt="tob"
            className="poster"
            src={process.env.PUBLIC_URL + "/assets/innovation.jpeg"}
          />
          <div className="body">
            Innovation & R&D plays crucially important role to today’s world,
            innovation as the key to business success. Due to the competing in
            global economy such as trade liberalization and fast changing in
            consumer taste, firms continually expand their budget for R&D and
            innovation in order to gain competitive advantage.
            <br />
            The speed of R&D refers to the company’s rate of innovation. R&D, as
            the necessary first step of innovation, plays an increasingly
            important role in specialization of scientific knowledge, increasing
            scale of types of industry and innovative process. Innovation can be
            defined as the successful exploitation of new idea.
            <ul>
              <li>
                <span className="title">Phytochem Transformation:</span>
                Our focus on the Future of Natural Medicine has our priority
                with the development of Sangam Herbals two decades ago, The
                Total Care Toothpaste was the first in line that has us in the
                Phytochem Transformation helping in all oral care problems and
                being one of the best performing products in the export maket.
                Followed by our patent formulation tackling the healthcare
                problems.
                <br />
                Our Lab and technicians are the core pillar in our growth to
                transform the Natural product segment for us and our clients
                globally.
              </li>
              <br />
              <li>
                <span className="title">
                  Education Technology “Accessibility”:
                </span>
                Coming Soon.
              </li>
              <br />
              <li>
                <span className="title">E-commerce VR Tech:</span>
                Coming Soon.
              </li>
              <br />
              <li>
                <span className="title">Automotive Solutions:</span>
                Coming Soon.
              </li>
              <br />
              <li>
                <span className="title">Fitness Technology:</span>
                Coming Soon.
              </li>
            </ul>
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">Innovation and R&D</div>
          <img
            alt="tob"
            className="poster"
            src={process.env.PUBLIC_URL + "/assets/innovation.jpeg"}
          />
          <div className="body">
            Innovation & R&D plays crucially important role to today’s world,
            innovation as the key to business success. Due to the competing in
            global economy such as trade liberalization and fast changing in
            consumer taste, firms continually expand their budget for R&D and
            innovation in order to gain competitive advantage.
            <br />
            The speed of R&D refers to the company’s rate of innovation. R&D, as
            the necessary first step of innovation, plays an increasingly
            important role in specialization of scientific knowledge, increasing
            scale of types of industry and innovative process. Innovation can be
            defined as the successful exploitation of new idea.
            <ul className="list">
              <li>
                <span className="head">Phytochem Transformation : </span>
                Our focus on the Future of Natural Medicine has our priority
                with the development of Sangam Herbals two decades ago, The
                Total Care Toothpaste was the first in line that has us in the
                Phytochem Transformation helping in all oral care problems and
                being one of the best performing products in the export maket.
                Followed by our patent formulation tackling the healthcare
                problems.
                <br />
                Our Lab and technicians are the core pillar in our growth to
                transform the Natural product segment for us and our clients
                globally.
              </li>
              <br />
              <li>
                <span className="head">
                  Education Technology “Accessibility”:
                </span>
                Coming Soon.
              </li>
              <br />
              <li>
                <span className="head">E-commerce VR Tech:</span>
                Coming Soon.
              </li>
              <br />
              <li>
                <span className="head">Automotive Solutions:</span>
                Coming Soon.
              </li>
              <br />
              <li>
                <span className="head">Fitness Technology:</span>
                Coming Soon.
              </li>
            </ul>
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RnD;
