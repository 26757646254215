import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import StoreBadge from "react-store-badge";

import "./autoloop.styles.scss";

const Autoloop = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  const images = [
    "/assets/auto/r1.png",
    "/assets/auto/r2.png",
    "/assets/auto/r3.png",
    "/assets/auto/r4.png",
    "/assets/auto/r5.png",
  ];
  const [currentImage, setCurrentImage] = useState(images[0]);
  let imageIndex = 0;

  useEffect(() => {
    const intervalId = setInterval(() => {
      imageIndex++;
      setCurrentImage(images[imageIndex % 5]);
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="autoloop">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="link" to="/csr-sustainability">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="link" to="/innovation-rnd">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <Grid container spacing={0} justify="center">
          {/* Left Section */}
          <Grid item xs={5} className="left-item">
            <img
              className="app-image"
              alt="mockup"
              src={process.env.PUBLIC_URL + currentImage}
            />
          </Grid>
          {/* Right Section */}
          <Grid item xs={7} className="right-item">
            <div className="right-content">
              <div className="title">Autoloop Vehicle Tags.</div>
              <div className="body">
                A Vehicle Alert/ Notification system allowing the user to get
                notifications and messages if the vehicle is causing disturbance
                on the streets, blocking the way to other vehicles, parked in a
                No Parking zone “Users contacted can be saved from the vehicles
                being towed or impounded” or any other vehicle related issue the
                owner can directly be informed.
                <br />
                <br />
                Easy to use “Free of Cost” service , vehicle communication
                system through vehicle I.D. a solution to daily street chaos.
                <br />
                <br />
                Maintaining user privacy, the user is contacted through vehicle
                registration number, without sharing the mobile number or
                personal details of the user.
              </div>
              <StoreBadge
                className="download-options"
                name="Autoloop"
                googlePlayUrl="https://play.google.com/store/apps/details?id=com.autoloop.android"
                appStoreUrl="https://apps.apple.com/app/autoloop/id6443796015"
              />
            </div>
          </Grid>
        </Grid>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">Autoloop Vehicle Tags.</div>
          <StoreBadge
            className="download-options"
            name="Autoloop"
            googlePlayUrl="https://play.google.com/store/apps/details?id=com.autoloop.android"
            appStoreUrl="https://apps.apple.com/app/autoloop/id6443796015"
          />
          <img
            className="app-image"
            alt="mockup"
            src={process.env.PUBLIC_URL + currentImage}
          />
          <div className="body">
            A Vehicle Alert/ Notification system allowing the user to get
            notifications and messages if the vehicle is causing disturbance on
            the streets, blocking the way to other vehicles, parked in a No
            Parking zone “Users contacted can be saved from the vehicles being
            towed or impounded” or any other vehicle related issue the owner can
            directly be informed.
            <br />
            <br />
            Easy to use “Free of Cost” service , vehicle communication system
            through vehicle I.D. a solution to daily street chaos.
            <br />
            <br />
            Maintaining user privacy, the user is contacted through vehicle
            registration number, without sharing the mobile number or personal
            details of the user.
          </div>
        </div>
        {/* Footer */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Autoloop;
