import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Grid,
  Paper,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import "./otc.styles.scss";

import pdf from "./OTCNeutraceuticals.pdf";

const OTC = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="otc">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="link" to="/csr-sustainability">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="link" to="/innovation-rnd">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div>
            <Paper className="item">
              <Grid container spacing={0} justify="center">
                <Grid item xs={4} className="left-item">
                  <img
                    className="prod-img"
                    alt="product"
                    src={process.env.PUBLIC_URL + "/assets/otc/otc1.png"}
                  />
                </Grid>
                <Grid item xs={4} className="left-item">
                  <img
                    className="prod-img"
                    alt="product"
                    src={process.env.PUBLIC_URL + "/assets/otc/otc2.png"}
                  />
                </Grid>
                <Grid item xs={4} className="left-item">
                  <img
                    className="prod-img"
                    alt="product"
                    src={process.env.PUBLIC_URL + "/assets/otc/otc3.png"}
                  />
                </Grid>
              </Grid>
            </Paper>
            <div className="text">
              Nutraceuticals are products, which other than nutrition are also
              used as medicine. A nutraceutical product may be defined as a
              substance, which has physiological benefit or provides protection
              against chronic disease. Nutraceuticals may be used to improve
              health, delay the aging process, prevent chronic diseases,
              increase life expectancy, or support the structure or function of
              the body. Nowadays, nutraceuticals have received considerable
              interest due to potential nutritional, safety and therapeutic
              effects. Recent studies have shown promising results for these
              compounds in various complications. In the present review much
              effort has been devoted to present new concepts about
              nutraceuticals based on their diseases modifying indications.
              Emphasis has been made to present herbal nutraceuticals effective
              on hard curative disorders related to oxidative stress including
              allergy, alzheimer, cardiovascular, cancer, diabetes, eye, immune,
              inflammatory and Parkinson's diseases as well as obesity.
              <br />
              Download the full list of{" "}
              <a href={pdf} download="OTCNeutraceuticals.pdf">
                products
              </a>{" "}
              here.
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">OTC Neutraceuticals</div>
          <img
            className="prod-img"
            alt="product"
            src={process.env.PUBLIC_URL + "/assets/otc/otc1.png"}
          />
          <img
            className="prod-img"
            alt="product"
            src={process.env.PUBLIC_URL + "/assets/otc/otc2.png"}
          />
          <img
            className="prod-img"
            alt="product"
            src={process.env.PUBLIC_URL + "/assets/otc/otc3.png"}
          />
          <div className="text">
            Nutraceuticals are products, which other than nutrition are also
            used as medicine. A nutraceutical product may be defined as a
            substance, which has physiological benefit or provides protection
            against chronic disease. Nutraceuticals may be used to improve
            health, delay the aging process, prevent chronic diseases, increase
            life expectancy, or support the structure or function of the body.
            Nowadays, nutraceuticals have received considerable interest due to
            potential nutritional, safety and therapeutic effects. Recent
            studies have shown promising results for these compounds in various
            complications. In the present review much effort has been devoted to
            present new concepts about nutraceuticals based on their diseases
            modifying indications. Emphasis has been made to present herbal
            nutraceuticals effective on hard curative disorders related to
            oxidative stress including allergy, alzheimer, cardiovascular,
            cancer, diabetes, eye, immune, inflammatory and Parkinson's diseases
            as well as obesity.
            <br />
            Download the full list of{" "}
            <a href={pdf} download="OTCNeutraceuticals.pdf">
              products
            </a>{" "}
            here.
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTC;
