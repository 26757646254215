import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Landing from "./pages/landing/landing.component";
import Business from "./pages/business/business.component";
import CSR from "./pages/csr/csr.component";
import RnD from "./pages/rnd/rnd.component";
import Career from "./pages/career/career.component";
import TnC from "./pages/tnc/tnc.component";
import Privacy from "./pages/privacy/privacy.component";

import Autoloop from "./pages/business/autoloop/autoloop.component";
import Ayurveda from "./pages/business/ayurveda/ayurveda.component";
import Biomye from "./pages/business/biomye/biomye.component";
import Manufacturing from "./pages/business/manufacturing/manufacturing.component";
import FMCG from "./pages/business/fmcg/fmcg.component";
import Healthcare from "./pages/business/healthcare/healthcare.component";
import Devices from "./pages/business/devices/devices.component";
import OTC from "./pages/business/otc/otc.component";
import Textiles from "./pages/business/textiles/textiles.component";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/tnc" element={<TnC />} />
        <Route path="/privacy" element={<Privacy />} />

        <Route path="/csr-sustainability" element={<CSR />} />
        <Route path="/innovation-rnd" element={<RnD />} />
        <Route path="/business" element={<Business />} />

        <Route path="/business/autoloop" element={<Autoloop />} />
        <Route path="/business/ayurveda-&-traditional" element={<Ayurveda />} />
        <Route path="/business/biomye" element={<Biomye />} />
        <Route path="/business/manufacturing" element={<Manufacturing />} />
        <Route path="/business/fmcg" element={<FMCG />} />
        <Route path="/business/healthcare" element={<Healthcare />} />
        <Route path="/business/medicinal-devices" element={<Devices />} />
        <Route path="/business/otc-neutraceuticals" element={<OTC />} />
        <Route path="/business/textiles" element={<Textiles />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
