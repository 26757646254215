import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import "./csr.styles.scss";

const CSR = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="csr">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="link" to="/business">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="link" to="/innovation-rnd">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">Tobacco Free Society</div>
          <img
            alt="tob"
            className="poster"
            src={process.env.PUBLIC_URL + "/assets/tobacco.jpeg"}
          />
          <div className="body">
            Nicotine contained in tobacco is profoundly habit-forming and
            tobacco use is a significant gamble factor for cardiovascular and
            respiratory sicknesses, north of 20 unique sorts or subtypes of
            disease, and numerous other crippling ailments. Consistently, in
            excess of 8 million individuals die from tobacco use. Most
            tobacco-related passings happen in low-and center pay nations, which
            are much of the time focuses of concentrated tobacco industry
            obstruction and promoting.
            <br />
            <br />
            Tobacco can likewise be destructive for non-smokers. Recycled smoke
            openness has likewise been embroiled in antagonistic wellbeing
            results, causing 1.2 million passings yearly. Almost 50% of all
            youngster’s inhale air dirtied by tobacco smoke and 65 000 kids pass
            on every year because of sicknesses connected with recycled smoke.
            Smoking while pregnant can prompt a few long-lasting medical issues
            for infants.
            <br />
            <br />
            Warmed tobacco items (HTPs) contain tobacco and open clients to
            poisonous emanations, a large number of which cause disease and are
            unsafe to wellbeing. Electronic nicotine conveyance frameworks
            (Finishes) and electronic non-nicotine conveyance frameworks
            (ENNDS), regularly known as e-cigarettes, don't contain tobacco and
            could conceivably contain nicotine, yet are destructive to wellbeing
            and without a doubt perilous.
            <br />
            <br />
            We are a proud No Tobacco Zone. Implementing it, and help fighting
            nicotine addiction.
          </div>
          <div className="title">Going Green</div>
          <img
            alt="tob"
            className="poster"
            src={process.env.PUBLIC_URL + "/assets/gogreen.jpeg"}
          />
          <div className="body">
            A majority biodegradable packaging used by us, and eyeing to get rid
            of plastic by 2030. <br />
            The slogan “going green” is seen and heard everywhere, but so many
            people don’t understand why go green. There are only so many natural
            resources and with the population to reach 9 billion by 2050,
            there’s going to be a lot less to go around. Burning of fossil fuels
            and other emissions causes increase in Carbon dioxide that leads to
            global warming. Landfills are a rising cause because they are filled
            with trash that can take millions of years to decompose leaving us
            with microplastics in the environment . Even such things as
            deforestation have taken a huge impact on our rain forests. Also we
            ruin animal’s habitats for our benefits causing many animals to
            become endangered.
            <br />
            <br />
            We implement Going Green in our daily lives planting 5000 trees
            yearly across the region and choosing plastic packing alternatives.
          </div>
          <div className="title">Waste Product Management</div>
          <img
            alt="tob"
            className="poster"
            src={process.env.PUBLIC_URL + "/assets/wastemanagement.jpg"}
          />
          <div className="body">
            Our waste product management strategy an organization uses to
            dispose, reduce, reuse, and prevent waste. Possible waste disposal
            methods used by us are recycling, composting and waste minimization.
            <br />
            <br />
            The alternatives for waste management is to reduce, reuse and
            recycle waste. We tend to reuse the majority of the used items, and
            having a fully digitalised paper free unit we lead the change in
            avoiding paper to ensure trees and the environment is left for the
            future generations.
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">Tobacco Free Society</div>
          <img
            alt="tob"
            className="poster"
            src={process.env.PUBLIC_URL + "/assets/tobacco.jpeg"}
          />
          <div className="body">
            Nicotine contained in tobacco is profoundly habit-forming and
            tobacco use is a significant gamble factor for cardiovascular and
            respiratory sicknesses, north of 20 unique sorts or subtypes of
            disease, and numerous other crippling ailments. Consistently, in
            excess of 8 million individuals die from tobacco use. Most
            tobacco-related passings happen in low-and center pay nations, which
            are much of the time focuses of concentrated tobacco industry
            obstruction and promoting.
            <br />
            <br />
            Tobacco can likewise be destructive for non-smokers. Recycled smoke
            openness has likewise been embroiled in antagonistic wellbeing
            results, causing 1.2 million passings yearly. Almost 50% of all
            youngster’s inhale air dirtied by tobacco smoke and 65 000 kids pass
            on every year because of sicknesses connected with recycled smoke.
            Smoking while pregnant can prompt a few long-lasting medical issues
            for infants.
            <br />
            <br />
            Warmed tobacco items (HTPs) contain tobacco and open clients to
            poisonous emanations, a large number of which cause disease and are
            unsafe to wellbeing. Electronic nicotine conveyance frameworks
            (Finishes) and electronic non-nicotine conveyance frameworks
            (ENNDS), regularly known as e-cigarettes, don't contain tobacco and
            could conceivably contain nicotine, yet are destructive to wellbeing
            and without a doubt perilous.
            <br />
            <br />
            We are a proud No Tobacco Zone. Implementing it, and help fighting
            nicotine addiction.
          </div>
          <div className="title">Going Green</div>
          <img
            alt="tob"
            className="poster"
            src={process.env.PUBLIC_URL + "/assets/gogreen.jpeg"}
          />
          <div className="body">
            A majority biodegradable packaging used by us, and eyeing to get rid
            of plastic by 2030. <br />
            The slogan “going green” is seen and heard everywhere, but so many
            people don’t understand why go green. There are only so many natural
            resources and with the population to reach 9 billion by 2050,
            there’s going to be a lot less to go around. Burning of fossil fuels
            and other emissions causes increase in Carbon dioxide that leads to
            global warming. Landfills are a rising cause because they are filled
            with trash that can take millions of years to decompose leaving us
            with microplastics in the environment . Even such things as
            deforestation have taken a huge impact on our rain forests. Also we
            ruin animal’s habitats for our benefits causing many animals to
            become endangered.
            <br />
            <br />
            We implement Going Green in our daily lives planting 5000 trees
            yearly across the region and choosing plastic packing alternatives.
          </div>
          <div className="title">Waste Product Management</div>
          <img
            alt="tob"
            className="poster"
            src={process.env.PUBLIC_URL + "/assets/wastemanagement.jpg"}
          />
          <div className="body">
            Our waste product management strategy an organization uses to
            dispose, reduce, reuse, and prevent waste. Possible waste disposal
            methods used by us are recycling, composting and waste minimization.
            <br />
            <br />
            The alternatives for waste management is to reduce, reuse and
            recycle waste. We tend to reuse the majority of the used items, and
            having a fully digitalised paper free unit we lead the change in
            avoiding paper to ensure trees and the environment is left for the
            future generations.
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CSR;
