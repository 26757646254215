import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Paper,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import {
  CollapsibleComponent,
  CollapsibleHead,
  CollapsibleContent,
} from "react-collapsible-component";

import "./devices.styles.scss";

const Devices = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  const [dd1, setDD1] = useState(false);
  const [dd2, setDD2] = useState(false);
  const [dd3, setDD3] = useState(false);
  const [dd4, setDD4] = useState(false);
  const [dd5, setDD5] = useState(false);

  let data = [
    {
      name: "Desire",
      image: process.env.PUBLIC_URL + "/assets/medicinal.png",
      content:
        "About is an integrated Healthcare brand with specialization in the hygiene product sphere. With our specialization in the Adult Diaper, Baby Diaper and Sanitary pads.",
    },
    {
      name: "Luvett",
      image: process.env.PUBLIC_URL + "/assets/luvette.png",
      content:
        "About is an integrated Healthcare brand with specialization in the hygiene product sphere. With our specialization in the Adult Diaper, Baby Diaper and Sanitary pads.",
    },
  ];

  return (
    <div className="devices">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="link" to="/csr-sustainability">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="link" to="/innovation-rnd">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div>
            <Paper className="item">
              <Grid container spacing={0} justify="center">
                <Grid item xs={6} className="left-item">
                  <img
                    className="prod-img"
                    alt="product"
                    src={process.env.PUBLIC_URL + "/assets/medicinal.png"}
                  />
                  <div className="prod-text">Desire</div>
                </Grid>
                <Grid item xs={6} className="left-item">
                  <img
                    className="prod-img"
                    alt="product"
                    src={process.env.PUBLIC_URL + "/assets/luvette.png"}
                  />
                  <div className="prod-text">Luvett</div>
                </Grid>
              </Grid>
            </Paper>
          </div>
          <div className="dropdown">
            <CollapsibleComponent>
              <CollapsibleHead className="dd-head">
                The Importance of Using a Condom:
                <Button className="dd-btn" onClick={() => setDD1(!dd1)}>
                  {dd1 ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </CollapsibleHead>
              <CollapsibleContent className="dd-body" isExpanded={dd1}>
                In a recent study performed at the University of New Orleans by
                Dr. Bruce M. King, only 20% of men reported using a condom
                consistently, while only 50% reported using protection with a
                new partner. The results of condom use or lack thereof is clear:
                <ul>
                  <li>
                    2/3 of all STIs occur in people 25 years of age or younger
                  </li>
                  <li>
                    By the age of 24, 1 in 3 sexually active people will have
                    contracted an STI In 2000, 15- to 19-year-old women had the
                    highest rate of gonorrhea compared to all other age
                    categories. (Sources: American Social Health Association,
                    Centers for Disease Control, and Planned Parenthood.)
                  </li>
                  <li>
                    There are many stigmas about using condoms. Many sexually
                    active college students simply don’t find sexual intercourse
                    with condoms as satisfying as sex without them. Often
                    students find it inconvenient, or expensive to use condoms.
                  </li>
                  <li>
                    However, all of these excuses are minuscule compared to the
                    dangerous and life-altering results of not using protection,
                    in addition all of these issues can be overcome.
                  </li>
                </ul>
              </CollapsibleContent>
              <CollapsibleHead className="dd-head">
                Aids and Condoms:
                <Button className="dd-btn" onClick={() => setDD2(!dd2)}>
                  {dd2 ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </CollapsibleHead>
              <CollapsibleContent className="dd-body" isExpanded={dd2}>
                The AIDS virus is spreading rapidly throughout India and Brazil.
                Due to the differences in culture and political policies, these
                two countries are attacking the AIDS epidemic problem in two
                totally different ways. On one hand, Brazil plans to spend
                hundreds of millions of dollars for teaching public AIDS
                awareness mainly to married women and Carnival goers. In
                comparison, India's government has identified specific targets
                of their society in order to reach the public and spread the
                information about AIDS awareness.
              </CollapsibleContent>
              <CollapsibleHead className="dd-head">
                Taking a look inside the male condom:
                <Button className="dd-btn" onClick={() => setDD3(!dd3)}>
                  {dd3 ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </CollapsibleHead>
              <CollapsibleContent className="dd-body" isExpanded={dd3}>
                Technology has been used to provide the most effective methods
                for birth control and to prevent sexually transmitted infections
                (STI) for centuries. However, many factors prevented safe sexual
                intercourse practices and birth control, such as political,
                ethical and moral issues. The condom is one of the most popular
                and effective method of contraception, and is the only
                technology available that prevents both pregnancy and STI’s.{" "}
                <br />
                <br />
                The condom works by preventing direct contact between the penis
                and the vagina, and acts as a physical barrier to prevent sperm
                from being fertilized by an egg of the sexual partner. It is
                placed on the erect or hardened penis. The condom must be
                removed before the erection ends, otherwise the condom might
                slip off and sperm could enter the vagina. (Condom, 2013)
                Condoms were made of latex in 2009. For people allergic to
                latex, plastic condoms are also available. It also contains
                lubricant and spermicide that destroys or damage the sperm.
                Spermicide can also be purchased in pharmacies. Condoms varies
                in color and size. (What Are Condoms, 2009) <br />
                <br />
                Condoms are the oldest barrier method of contraception used by a
                male. It is suggested that the Ancient Egyptians were the first
                to use linen sheath for protection against diseases. However,
                there are no evidence to prove that they did. Condoms were used
                for both birth control and as a prevention of STI’s. (History of
                the condom , 1993) Condoms were made of animal materials such as
                bladders and intestines during the Renaissance era, and these
                types of condom are still in use nowadays and provides a choice
                for people with latex allergies. Leather condoms were also used
                in the Renaissance.
              </CollapsibleContent>
              <CollapsibleHead className="dd-head">
                Advantages and Disadvantages of Women Condoms:
                <Button className="dd-btn" onClick={() => setDD4(!dd4)}>
                  {dd4 ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </CollapsibleHead>
              <CollapsibleContent className="dd-body" isExpanded={dd4}>
                Women condoms is a contraceptive product created from a thin
                latex rubber, which is inserted into a woman&#39;s vagina before
                sexual actions. Women condoms are used inside the vagina to
                avert semen reaching the womb. Women condoms aid to prevent the
                man’s sperm to reach one of the females’ eggs by putting the egg
                and also sperm away from each other or perhaps by preventing egg
                creation in the female body; by holding the male&#39;s sperm
                inside the condom and also out of the vagina.
                <br />
                <br />
                Once applied properly during vaginal intercourse, female condom
                easily helps to defend against getting pregnant and also protect
                against HIV and other sexual transmitted diseases (STIs). It is
                commonly recommended that condoms are the only real
                contraception that defends.
                <br />
                <br />
                Most women would prefer using the female condom simply because
                it implies not having to depend on the male partner to put on a
                condom.
                <br />
                <br />
                The woman condom is a contraceptive sheath (or simply pouch)
                that will assist you be in control of your own sexual
                healthiness and also avoid an unforeseen pregnancy as well as
                sexual transmitted diseases (STIs), such as HIV
                <br />
                <br />
                ADVANTAGES OF USING WOMEN CONDOMS:
                <ul>
                  <li>
                    By avoiding the sharing of human bodily fluid (semen or
                    vaginal fluids), women condoms assist to defend against
                    numerous STIs, such as HIV.
                  </li>
                  <li>
                    If used properly and also regularly, condoms are a
                    dependable technique of avoiding pregnancy.
                  </li>
                  <li>
                    It is important to utilize them should you want to have sex.
                  </li>
                </ul>
                Be careful to apply condoms properly, and also give some thought
                to making use of other types of contraception for additional
                protection.
              </CollapsibleContent>
              <CollapsibleHead className="dd-head">
                RELATIONSHIP BENEFITS OF USING A FEMALE CONDOM:
                <Button className="dd-btn" onClick={() => setDD5(!dd5)}>
                  {dd5 ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </CollapsibleHead>
              <CollapsibleContent className="dd-body" isExpanded={dd5}>
                <ul>
                  <li>
                    Women condoms maximize pleasures for the two partners during
                    sexual activity.
                    <br />
                    During the course of vaginal intercourse, the women condom’s
                    external ring rubs against the clitoris even when the
                    internal ring tends to stimulate the cap of the penis. The
                    female condom is amazingly wide and also pre-lubricated,
                    which makes some men say it feels much better. Should you be
                    hunting for a wet or exotic time, you’ll be glad learn that
                    the women condom is harmless to employ with either water- or
                    silicone-based lubes.
                  </li>
                  <li>
                    Women condoms are a lot of fun for foreplay. Putting on
                    female condom is usually a sexy option to define the mood.
                    Simply because applying the inner ring around your main
                    vulva and also clitoris in the course of the insertion, this
                    can also add a little extra arousal—which often is a major
                    turn-on for your lover as well.
                  </li>
                </ul>
              </CollapsibleContent>
            </CollapsibleComponent>
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">Condoms</div>
          <div>
            {data.map((c) => (
              <div className="item">
                <img className="prod-img" alt="product" src={c.image} />
                <div className="title">{c.name}</div>
              </div>
            ))}
          </div>
          <div className="dropdown">
            <CollapsibleComponent>
              <CollapsibleHead className="dd-head">
                The Importance of Using a Condom:
                <Button className="dd-btn" onClick={() => setDD1(!dd1)}>
                  {dd1 ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </CollapsibleHead>
              <CollapsibleContent className="dd-body" isExpanded={dd1}>
                In a recent study performed at the University of New Orleans by
                Dr. Bruce M. King, only 20% of men reported using a condom
                consistently, while only 50% reported using protection with a
                new partner. The results of condom use or lack thereof is clear:
                <ul>
                  <li>
                    2/3 of all STIs occur in people 25 years of age or younger
                  </li>
                  <li>
                    By the age of 24, 1 in 3 sexually active people will have
                    contracted an STI In 2000, 15- to 19-year-old women had the
                    highest rate of gonorrhea compared to all other age
                    categories. (Sources: American Social Health Association,
                    Centers for Disease Control, and Planned Parenthood.)
                  </li>
                  <li>
                    There are many stigmas about using condoms. Many sexually
                    active college students simply don’t find sexual intercourse
                    with condoms as satisfying as sex without them. Often
                    students find it inconvenient, or expensive to use condoms.
                  </li>
                  <li>
                    However, all of these excuses are minuscule compared to the
                    dangerous and life-altering results of not using protection,
                    in addition all of these issues can be overcome.
                  </li>
                </ul>
              </CollapsibleContent>
              <CollapsibleHead className="dd-head">
                Aids and Condoms:
                <Button className="dd-btn" onClick={() => setDD2(!dd2)}>
                  {dd2 ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </CollapsibleHead>
              <CollapsibleContent className="dd-body" isExpanded={dd2}>
                The AIDS virus is spreading rapidly throughout India and Brazil.
                Due to the differences in culture and political policies, these
                two countries are attacking the AIDS epidemic problem in two
                totally different ways. On one hand, Brazil plans to spend
                hundreds of millions of dollars for teaching public AIDS
                awareness mainly to married women and Carnival goers. In
                comparison, India's government has identified specific targets
                of their society in order to reach the public and spread the
                information about AIDS awareness.
              </CollapsibleContent>
              <CollapsibleHead className="dd-head">
                Taking a look inside the male condom:
                <Button className="dd-btn" onClick={() => setDD3(!dd3)}>
                  {dd3 ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </CollapsibleHead>
              <CollapsibleContent className="dd-body" isExpanded={dd3}>
                Technology has been used to provide the most effective methods
                for birth control and to prevent sexually transmitted infections
                (STI) for centuries. However, many factors prevented safe sexual
                intercourse practices and birth control, such as political,
                ethical and moral issues. The condom is one of the most popular
                and effective method of contraception, and is the only
                technology available that prevents both pregnancy and STI’s.{" "}
                <br />
                <br />
                The condom works by preventing direct contact between the penis
                and the vagina, and acts as a physical barrier to prevent sperm
                from being fertilized by an egg of the sexual partner. It is
                placed on the erect or hardened penis. The condom must be
                removed before the erection ends, otherwise the condom might
                slip off and sperm could enter the vagina. (Condom, 2013)
                Condoms were made of latex in 2009. For people allergic to
                latex, plastic condoms are also available. It also contains
                lubricant and spermicide that destroys or damage the sperm.
                Spermicide can also be purchased in pharmacies. Condoms varies
                in color and size. (What Are Condoms, 2009) <br />
                <br />
                Condoms are the oldest barrier method of contraception used by a
                male. It is suggested that the Ancient Egyptians were the first
                to use linen sheath for protection against diseases. However,
                there are no evidence to prove that they did. Condoms were used
                for both birth control and as a prevention of STI’s. (History of
                the condom , 1993) Condoms were made of animal materials such as
                bladders and intestines during the Renaissance era, and these
                types of condom are still in use nowadays and provides a choice
                for people with latex allergies. Leather condoms were also used
                in the Renaissance.
              </CollapsibleContent>
              <CollapsibleHead className="dd-head">
                Advantages and Disadvantages of Women Condoms:
                <Button className="dd-btn" onClick={() => setDD4(!dd4)}>
                  {dd4 ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </CollapsibleHead>
              <CollapsibleContent className="dd-body" isExpanded={dd4}>
                Women condoms is a contraceptive product created from a thin
                latex rubber, which is inserted into a woman&#39;s vagina before
                sexual actions. Women condoms are used inside the vagina to
                avert semen reaching the womb. Women condoms aid to prevent the
                man’s sperm to reach one of the females’ eggs by putting the egg
                and also sperm away from each other or perhaps by preventing egg
                creation in the female body; by holding the male&#39;s sperm
                inside the condom and also out of the vagina.
                <br />
                <br />
                Once applied properly during vaginal intercourse, female condom
                easily helps to defend against getting pregnant and also protect
                against HIV and other sexual transmitted diseases (STIs). It is
                commonly recommended that condoms are the only real
                contraception that defends.
                <br />
                <br />
                Most women would prefer using the female condom simply because
                it implies not having to depend on the male partner to put on a
                condom.
                <br />
                <br />
                The woman condom is a contraceptive sheath (or simply pouch)
                that will assist you be in control of your own sexual
                healthiness and also avoid an unforeseen pregnancy as well as
                sexual transmitted diseases (STIs), such as HIV
                <br />
                <br />
                ADVANTAGES OF USING WOMEN CONDOMS:
                <ul>
                  <li>
                    By avoiding the sharing of human bodily fluid (semen or
                    vaginal fluids), women condoms assist to defend against
                    numerous STIs, such as HIV.
                  </li>
                  <li>
                    If used properly and also regularly, condoms are a
                    dependable technique of avoiding pregnancy.
                  </li>
                  <li>
                    It is important to utilize them should you want to have sex.
                  </li>
                </ul>
                Be careful to apply condoms properly, and also give some thought
                to making use of other types of contraception for additional
                protection.
              </CollapsibleContent>
              <CollapsibleHead className="dd-head">
                RELATIONSHIP BENEFITS OF USING A FEMALE CONDOM:
                <Button className="dd-btn" onClick={() => setDD5(!dd5)}>
                  {dd5 ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </CollapsibleHead>
              <CollapsibleContent className="dd-body" isExpanded={dd5}>
                <ul>
                  <li>
                    Women condoms maximize pleasures for the two partners during
                    sexual activity.
                    <br />
                    During the course of vaginal intercourse, the women condom’s
                    external ring rubs against the clitoris even when the
                    internal ring tends to stimulate the cap of the penis. The
                    female condom is amazingly wide and also pre-lubricated,
                    which makes some men say it feels much better. Should you be
                    hunting for a wet or exotic time, you’ll be glad learn that
                    the women condom is harmless to employ with either water- or
                    silicone-based lubes.
                  </li>
                  <li>
                    Women condoms are a lot of fun for foreplay. Putting on
                    female condom is usually a sexy option to define the mood.
                    Simply because applying the inner ring around your main
                    vulva and also clitoris in the course of the insertion, this
                    can also add a little extra arousal—which often is a major
                    turn-on for your lover as well.
                  </li>
                </ul>
              </CollapsibleContent>
            </CollapsibleComponent>
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Devices;
