import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import "./privacy.styles.scss";

const Privacy = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="privacy">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="selected" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="link" to="/business">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="link" to="/csr-sustainability">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="link" to="/innovation-rnd">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">Privacy Policy</div>
          <div className="body">
            This <span className="bold">Privacy Policy</span> outlines{" "}
            <span className="bold">Abiomye Industries Private Limited’s</span>{" "}
            approach to Data Protection and Privacy to fulfill its obligations
            under the{" "}
            <span className="bold">applicable laws and regulations</span> . This
            Privacy Policy applies to your Personal Data which is processed by
            us, whether in physical or electronic mode.
            <br />
            <br />
            In this Privacy Policy, the expressions ‘Personal Data’, ‘Data
            Subject’, ‘Controller’, ‘Processor’ and ‘Processing’ shall have the
            meanings given to them in the{" "}
            <span className="bold">applicable privacy laws</span>.
            <br />
            <br />
            We are committed to treating data privacy seriously. It is important
            that you know exactly what we do with your Personal Data.
            <br />
            <br />
            Throughout this document, “we”, “us”, “our”, “ours” refer to Abiomye
            Industries Private Limited . Wherever we have said ‘you’ or ‘your’,
            this means YOU
            <br />
            <br />
            <span className="subtitle">WHO ARE WE</span>
            <br />
            <br />
            Abiomye Industries Private Limited is a company incorporated and
            registered under the provisions of the Companies Act, 2013 and
            having its registered office at B-53/D, Dilshad Colony, New Delhi
            110095. Abiomye Industries Private Limited is engaged in the
            business through the mobile applications (“App”) both developed and
            owned by Abiomye Industries Private Limited and/or its parent
            company, and/or its affiliates (Website and App collectively
            referred to as “Platform”).
            <br />
            <br />
            <span className="subtitle">ROLES WE PLAY</span>
            <br />
            <br />
            We play the role of a Data Controller when we collect and process
            Personal Data about you.
            <br />
            <br />
            We play the role of a Data Processor when we collect and process
            Personal Data on behalf of another Data Controller
            <br />
            <br />
            <span className="subtitle">OUR COMMITMENT</span>
            <br />
            <br />
            We commit to protecting your privacy and hence our Personal Data
            handling practices are continually reviewed to ensure compliance
            with the applicable Privacy laws and regulations
            <br />
            <br />
            <span className="subtitle">
              PERSONAL INFORMATION GATHERED BY Autoloop
            </span>
            <br />
            <br />
            The information we learn and gather from you, personal or otherwise,
            is used to register you, verify your identity to permit you to use
            the app, undertake transactions (including to facilitate and process
            payments), communicate with you,convey any promotional offers,
            services or updates associated with Autoloop, and generally maintain
            your accounts with us. We also use this information to customize
            your experience and improve Autoloop.
            <br />
            <br />
            <span className="subtitle">INFORMATION YOU GIVE US</span>
            <br />
            <br />
            We receive and store any information you provide while using
            Autoloop, or give us in any other way. You can choose not to provide
            certain information, but then you might not be able to use Autoloop.
            We use the information that you provide for such purposes as opening
            your account, processing your transactions, responding to your
            requests, and communicating with you
            <br />
            <br />
            <span className="subtitle">INFORMATION WE COLLECT FROM YOU</span>
            <br />
            <br />
            We receive and store certain types of information whenever you
            interact with us. For example, like many websites, we use “cookies,”
            and we obtain certain types of information when your web browser
            accesses our Services. We may also receive/store information about
            your location and your mobile device, including a unique identifier
            for your device. We may use this information for internal analysis
            and to provide you with location-based services, such as
            advertising, search results, and other personalized content.
            <br />
            <br />
            <span className="subtitle">INFORMATION FROM OTHER RESOURCES</span>
            <br />
            <br />
            We might receive information about you from other sources, such as
            updated delivery and address information from our carriers, which we
            use to correct our records and deliver your next purchase more
            easily.
            <br />
            <br />
            <span className="subtitle">CATEGORIES OF PERSONAL DATA</span>
            <br />
            <br />
            Categories of Personal Data collected and processed by us are as
            follows :
            <ul>
              <li>
                Demographic & Identity Data
                <ul>
                  <li>
                    Contact details such as Name, email address, contact number,
                    shipping address, country, date of birth, profile picture
                  </li>
                  <li>
                    Open data and public records such as information about YOU
                    that is openly available on the internet
                  </li>
                  <li>
                    Details such as Transaction amount, Bank Name, Card Type,
                    Card number.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                Online Identifiers and other Technical Data
                <ul>
                  <li>
                    Location details such as data we get about your location, IP
                    address, logs, or from where you connect a computer to the
                    internet
                  </li>
                  <li>
                    Technical details such as device information, location and
                    network carrier when you use our mobile applications
                  </li>
                  <li>
                    Communications details such as the Metadata and other
                    Personal Data we get from communications done through
                    e-mails, SMS, instant messages and calls
                  </li>
                  <li>
                    Usage data details such as data about how you use our
                    website or web-based properties, pages viewed, etc.
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            <span className="subtitle">CONSENT</span>
            <br />
            <br />
            By using the Website/App and/ or by providing your information, you
            consent to the collection and use of the information you disclose on
            the Website/App in accordance with this Privacy Policy, including
            but not limited to your consent for sharing your information as per
            this privacy policy.
            <ul>
              <li>
                PURPOSES OF GATHERING YOUR PERSONAL DATA
                <br />
                We use your personal information to operate, provide, develop,
                and improve the products and services. These purposes include:
                <br />
                <br />
                <ul>
                  <li>
                    PURCHASE AND DELIVERY OF PRODUCTS AND SERVICES
                    <br />
                    We use your personal information to take and handle orders,
                    deliver products and services, process payments, and
                    communicate with you about orders, products and services,
                    and promotional offers.
                  </li>
                  <br />
                  <li>
                    PROVIDE, TROUBLESHOOT, AND IMPROVE Autoloop
                    <br />
                    We use your personal information to provide functionality,
                    analyze performance, fix errors, and improve the usability
                    and effectiveness of the Autoloop.
                  </li>
                  <br />
                  <li>
                    RECOMMENDATIONS AND PERSONALIZATION
                    <br />
                    We use your personal information to recommend features,
                    products, and services that might be of interest to you,
                    identify your preferences, and personalize your experience
                    with Autoloop.
                  </li>
                  <br />
                  <li>
                    COMMUNICATE WITH YOU
                    <br />
                    We use your personal information to communicate with you in
                    relation to Autoloop via different channels (e.g., by phone,
                    e-mail, chat).
                  </li>
                  <br />
                  <li>
                    ADVERTISING
                    <br />
                    We use your personal information to display interest-based
                    ads for features, products, and services that might be of
                    interest to you.
                  </li>
                  <br />
                </ul>
              </li>
            </ul>
            <br />
            <span className="subtitle">
              LAWFUL BASES OF PROCESSING YOUR PERSONAL DATA
            </span>
            <br />
            <br />
            We are permitted to process your Personal Data in compliance with
            applicable laws and regulations by relying on one or more of the
            following lawful bases:
            <ul>
              <li>
                You have explicitly agreed to us processing your Personal Data
                for a specific reason
              </li>
              <li>
                The processing is necessary to perform the agreement we have
                with you or to take steps to enter into an agreement with you
              </li>
              <li>
                The processing is necessary to be in compliance with our Legal
                Obligations
              </li>
              <li>
                The processing is necessary for the purposes of a legitimate
                interest (“Legitimate Interest”) pursued by us, such as:
                <ul>
                  <li>to provide services to you,</li>
                  <li>
                    to evaluate, develop or improve our products and services
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            Where the processing is based on your consent, you have a right to
            withdraw your consent at any time. You may withdraw consent by
            contacting us. Upon receipt of your written request to withdraw your
            consent, consequences of withdrawal will be communicated to you and,
            upon your agreement, your request for withdrawal will be processed.
            <br />
            <br />
            <span className="subtitle">
              COOKIES AND OTHER TRACKERS USED BY OUR DIGITAL PROPERTIES
            </span>
            <br />
            <br />
            Cookies are small text files that are placed on your computer by
            websites that you visit. Similarly, there are other types of
            trackers used. Likewise, Mobile Apps use requisite permissions and
            SDKs. These are used to make Websites &amp; Apps work, or work more
            efficiently, as well as to provide information to the owners of the
            website/ App.
            <br />
            <br />
            We use cookies, permissions and other trackers in our website,
            web-based properties and mobile applications that are used to
            collect and process data about you so we can provide you a better
            online experience as well as improve our services
            <br />
            <br />
            Below are the categories of cookies used on our website along with a
            description of what they are used for :
            <ul>
              <li>
                <span className="bold">[STRICTLY NECESSARY]</span> These cookies
                are needed to run our website, to keep it secure if you are
                logged on and to obey regulations that apply to us.
              </li>
              <li>
                <span className="bold">[STRICTLY NECESSARY]</span> If you are a
                customer, they help us know who you are so that you can log on
                and manage your accounts. They also help us keep your details
                safe and private.
              </li>
              <li>
                <span className="bold">[FUNCTIONAL]</span> These cookies are
                used for remembering things like:
                <ul>
                  <li>Your user ID on the log on page</li>
                  <li>Your region or country</li>
                  <li>Your preferred language</li>
                  <li>
                    Accessibility options like large font or high contrast pages
                  </li>
                </ul>
              </li>
              <li>
                <span className="bold">[PERFORMANCE]</span> These cookies tell
                us how you and our other customers use our website. We combine
                all this data together and study it. This helps us to:
                <ul>
                  <li>Improve the performance of our services</li>
                  <li>Improve the products we provide</li>
                </ul>
              </li>
            </ul>
            Most web browsers allow some control of most cookies through the
            browser settings. Please note disabling the{" "}
            <span className="bold">Strictly Necessary</span> cookies may cause
            certain parts of our website to remain inaccessible to you.
            <br />
            <br />
            We use other tracking mechanisms in addition to the above
            <ul>
              <li>Pixel tags</li>
              <li>Log Files</li>
              <li>Clickstream analytics</li>
            </ul>
            <br />
            <span className="bold">DO NOT TRACK</span>
            <br />
            <br />
            Some web browsers have a “Do Not Track” feature. This feature lets
            you tell websites you visit that you do not want to have your online
            activity tracked. These features are not yet uniform across
            browsers. Our sites are not currently set up to respond to those
            signals.
            <br />
            <br />
            <span className="bold">PERSONAL DATA DISCLOSURE</span>
            <br />
            <br />
            Autoloop app contains third party SDKs that collect personally
            identifiable information (PII). They use this information to better
            target users or provide elements of our products &amp; services on
            behalf of us.These third party sites have separate and independent
            privacy policies.
            <br />
            <br />
            <span className="bold">Autoloop USES 3 TYPES OF SDKs: </span>
            <br />
            <br />
            <table>
              <tr>
                <th>SDK</th>
                <th>Purpose</th>
              </tr>
              <tr>
                <td>Analytics</td>
                <td>
                  <ul>
                    <li>Settle errors</li>
                    <li>Better target users</li>
                    <li>
                      Provide push notifications and digital ads to the users
                    </li>
                    <li>Example-Clevertap,Appsflyer,Crashlytics, Gamooga</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Payment</td>
                <td>
                  Complete customers’ payment transaction Example – PayU,
                  Razorpay, Cashfree
                </td>
              </tr>
              <tr>
                <td>Login</td>
                <td>
                  Help users login at Autoloop Example – Google, Facebook.
                </td>
              </tr>
            </table>
            <br />
            We might also disclose your Personal Data to appropriate authorities
            if we believe that it is reasonably necessary to comply with a law,
            regulation, legal process, to protect the safety of any person, to
            address fraud, security, or technical issues, or to protect our
            rights or the rights of those who use our products &amp; services.
            <br />
            <br />
            <span className="bold">
              Reasons for disclosing your Personal Data to other parties:
            </span>
            <br />
            <br />
            We may disclose your Personal Data to others where it is lawful to
            do so including where we or they:
            <ul>
              <li>need to provide you with products or services</li>
              <li>where you have initiated a payment</li>
              <li>
                have a legal obligation to do so, e.g. to assist with detecting
                and preventing fraud,
              </li>
              <li>
                have requirement in connection with regulatory reporting,
                litigation or asserting or defending legal rights and interests
              </li>
              <li>have a legitimate business reason for doing so</li>
              <li>
                want to send you information and promotional material regarding
                our products and services,
              </li>
              <li>
                have asked you for your consent to share it, and you’ve agreed
              </li>
              <li>Improve your online experience for using our product.</li>
            </ul>
            <br />
            <span className="bold">
              To whom your Personal Data may be disclosed:
            </span>
            <br />
            <br />
            We may disclose your Personal Data for the above purposes to other
            parties including:
            <ul>
              <li>
                Other group companies and any subcontractors, agents or service
                providers who work for us or provide services to us or group
                companies (including their employees, sub-contractors, service
                providers, directors and officers)
              </li>
              <li>
                Law Enforcement Authorities, Government Authorities, courts,
                dispute resolution bodies, our regulators, auditors and any
                party appointed or requested by our regulators to carry out
                investigations or audits of our activities
              </li>
              <li>
                Statutory and regulatory bodies and authorities (including the
                government) investigating agencies and entities or persons, to
                whom or before whom it is mandatory to disclose the Personal
                Data as per the applicable law, courts, judicial and
                quasi-judicial authorities and tribunals, arbitrators and
                arbitration tribunals
              </li>
              <li>Overseas regulators</li>
              <li>
                Anybody else that we’ve been instructed to share your Personal
                Data with by you
              </li>
            </ul>
            <br />
            <span className="subtitle">CROSS-BORDER DATA TRANSFER</span>
            <br />
            <br />
            Personal Data we hold about you may be transferred to other
            countries outside your residential country for any of the purposes
            described in this Privacy Policy.
            <br />
            <br />
            You understand and accept that these countries may have differing
            (and potentially less stringent) laws relating to the degree of
            confidentiality afforded to the information it holds and that such
            information can become subject to the laws and disclosure
            requirements of such countries, including disclosure to governmental
            bodies, regulatory agencies and private persons, as a result of
            applicable governmental or regulatory inquiry, court order or other
            similar process. In addition, a number of countries have agreements
            with other countries providing for exchange of information for law
            enforcement, tax and other purposes.
            <br />
            <br />
            If we transfer your Personal Data to third parties for purposes
            stated in this Privacy Policy, we will use best endeavors to put in
            place appropriate controls and safeguards to ensure that your
            Personal Data is kept accurate, adequately protected, and processed
            only for specified and reasonable purposes in a manner that is fair,
            transparent and has a lawful basis, and is stored for no longer than
            is absolutely necessary.
            <br />
            <br />
            <span className="subtitle">DATA SECURITY</span>
            <br />
            <br />
            We are committed to protecting your Personal Data in our custody. We
            take reasonable steps to ensure appropriate physical, technical and
            managerial safeguards are in place to protect your Personal Data
            from unauthorized access, alteration, transmission and deletion. We
            work to protect the security of your personal information during
            transmission by using encryption protocols. We use multi-layered
            controls to help protect our infrastructure, constantly monitoring
            and improving our applications, systems, and processes to meet the
            growing demands and challenges of security.We ensure that the third
            parties who provide services to us under appropriate contracts, take
            appropriate security measures to protect your Personal Data in line
            with our policies.
            <br />
            <br />
            <span className="subtitle">RETENTION AND DISPOSAL OF DATA</span>
            <br />
            <br />
            We keep the Personal Data we collect about you on our systems or
            with third parties for as long as it is required for the purposes
            set out in this Privacy Policy and for legal or regulatory reasons.
            We will only use your Personal Data for those purposes and will make
            sure that your Privacy is protected. We shall take reasonable steps
            to delete or permanently de-identify Personal Data that is no longer
            needed.
            <br />
            <br />
            <span className="subtitle">LINKS TO OTHER WEBSITES</span>
            <br />
            <br />
            Our website may contain links to websites of other organisations.
            This privacy policy does not cover how that organisation processes
            Personal Data. We encourage you to read the privacy policies on the
            other websites you visit.
            <br />
            <br />
            <span className="subtitle">CHILDREN’S PRIVACY</span>
            <br />
            <br />
            Our website is directed to be used by adults only. If you are not an
            adult, while you may look at our site, you should not make a
            purchase, register, or submit Personal Data to us. We or our
            associates /affiliates do not knowingly collect information from
            minors.
            <br />
            <br />
            <span className="subtitle">YOUR RIGHTS AS A CUSTOMER</span>
            <br />
            <br />
            We understand that when you interact with Autoloop, you have rights
            over your Personal Data. These rights involve providing reasonable
            steps to allow you to access your personal data, correct any errors
            among others. In the event that you are not satisfied with our
            response or have unresolved concerns, you can get in touch with us
            to resolve the issue by means of{" "}
            <a href="emailto:privacy@biomye.com">privacy@biomye.com.</a>
            <br />
            <br />
            <span className="subtitle">CONTACT US</span>
            <br />
            <br />
            For any further queries and complaints related to privacy under
            applicable laws and regulations, you could reach us at:
            <br />
            Contact Email Address:{" "}
            <a href="mailto:support@biomye.com">support@biomye.com</a>
            <br />
            <br />
            <span className="bold">
              IN ACCORDANCE WITH INFORMATION TECHNOLOGY ACT 2000 AND RULES MADE
              THERE UNDER, CONSUMER PROTECTION ACT 2019 AND RULES MADE THERE
              UNDER
            </span>
            <br />
            <br />
            GRIEVANCE OFFICER – CUSTOMER SERVICES
            <br />
            Name : Mr. Ajay Singh
            <br />
            Email :{" "}
            <a href="mailto:grevianceofficer@biomye.com">
              grevianceofficer@biomye.com
            </a>
            <br />
            <br />
            <span className="bold">
              FOR LAW ENFORCEMENT AND COMPLIANCE UNDER CONSUMER PROTECTION ACT
              2019 AND RULES MADE THERE UNDER
            </span>
            <br />
            <br />
            NODAL OFFICER
            <br />
            Email :{" "}
            <a href="mailto:nodalofficer@biomye.com">nodalofficer@biomye.com</a>
            <br />
            <br />
            <span className="subtitle">NOTIFICATION OF CHANGES</span>
            <br />
            <br />
            We keep our Privacy Policy under regular review to make sure it is
            up to date and accurate. Any changes we may make to this Privacy
            Policy in the future will be posted on this page. We recommend that
            you re-visit this page regularly to check for any updates.
            <br />
            <br />
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">Privacy Policy</div>
          <div className="body">
            This <span className="bold">Privacy Policy</span> outlines{" "}
            <span className="bold">Abiomye Industries Private Limited’s</span>{" "}
            approach to Data Protection and Privacy to fulfill its obligations
            under the{" "}
            <span className="bold">applicable laws and regulations</span> . This
            Privacy Policy applies to your Personal Data which is processed by
            us, whether in physical or electronic mode.
            <br />
            <br />
            In this Privacy Policy, the expressions ‘Personal Data’, ‘Data
            Subject’, ‘Controller’, ‘Processor’ and ‘Processing’ shall have the
            meanings given to them in the{" "}
            <span className="bold">applicable privacy laws</span>.
            <br />
            <br />
            We are committed to treating data privacy seriously. It is important
            that you know exactly what we do with your Personal Data.
            <br />
            <br />
            Throughout this document, “we”, “us”, “our”, “ours” refer to Abiomye
            Industries Private Limited . Wherever we have said ‘you’ or ‘your’,
            this means YOU
            <br />
            <br />
            <span className="subtitle">WHO ARE WE</span>
            <br />
            <br />
            Abiomye Industries Private Limited is a company incorporated and
            registered under the provisions of the Companies Act, 2013 and
            having its registered office at B-53/D, Dilshad Colony, New Delhi
            110095. Abiomye Industries Private Limited is engaged in the
            business through the mobile applications (“App”) both developed and
            owned by Abiomye Industries Private Limited and/or its parent
            company, and/or its affiliates (Website and App collectively
            referred to as “Platform”).
            <br />
            <br />
            <span className="subtitle">ROLES WE PLAY</span>
            <br />
            <br />
            We play the role of a Data Controller when we collect and process
            Personal Data about you.
            <br />
            <br />
            We play the role of a Data Processor when we collect and process
            Personal Data on behalf of another Data Controller
            <br />
            <br />
            <span className="subtitle">OUR COMMITMENT</span>
            <br />
            <br />
            We commit to protecting your privacy and hence our Personal Data
            handling practices are continually reviewed to ensure compliance
            with the applicable Privacy laws and regulations
            <br />
            <br />
            <span className="subtitle">
              PERSONAL INFORMATION GATHERED BY Autoloop
            </span>
            <br />
            <br />
            The information we learn and gather from you, personal or otherwise,
            is used to register you, verify your identity to permit you to use
            the app, undertake transactions (including to facilitate and process
            payments), communicate with you,convey any promotional offers,
            services or updates associated with Autoloop, and generally maintain
            your accounts with us. We also use this information to customize
            your experience and improve Autoloop.
            <br />
            <br />
            <span className="subtitle">INFORMATION YOU GIVE US</span>
            <br />
            <br />
            We receive and store any information you provide while using
            Autoloop, or give us in any other way. You can choose not to provide
            certain information, but then you might not be able to use Autoloop.
            We use the information that you provide for such purposes as opening
            your account, processing your transactions, responding to your
            requests, and communicating with you
            <br />
            <br />
            <span className="subtitle">INFORMATION WE COLLECT FROM YOU</span>
            <br />
            <br />
            We receive and store certain types of information whenever you
            interact with us. For example, like many websites, we use “cookies,”
            and we obtain certain types of information when your web browser
            accesses our Services. We may also receive/store information about
            your location and your mobile device, including a unique identifier
            for your device. We may use this information for internal analysis
            and to provide you with location-based services, such as
            advertising, search results, and other personalized content.
            <br />
            <br />
            <span className="subtitle">INFORMATION FROM OTHER RESOURCES</span>
            <br />
            <br />
            We might receive information about you from other sources, such as
            updated delivery and address information from our carriers, which we
            use to correct our records and deliver your next purchase more
            easily.
            <br />
            <br />
            <span className="subtitle">CATEGORIES OF PERSONAL DATA</span>
            <br />
            <br />
            Categories of Personal Data collected and processed by us are as
            follows :
            <ul>
              <li>
                Demographic & Identity Data
                <ul>
                  <li>
                    Contact details such as Name, email address, contact number,
                    shipping address, country, date of birth, profile picture
                  </li>
                  <li>
                    Open data and public records such as information about YOU
                    that is openly available on the internet
                  </li>
                  <li>
                    Details such as Transaction amount, Bank Name, Card Type,
                    Card number.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                Online Identifiers and other Technical Data
                <ul>
                  <li>
                    Location details such as data we get about your location, IP
                    address, logs, or from where you connect a computer to the
                    internet
                  </li>
                  <li>
                    Technical details such as device information, location and
                    network carrier when you use our mobile applications
                  </li>
                  <li>
                    Communications details such as the Metadata and other
                    Personal Data we get from communications done through
                    e-mails, SMS, instant messages and calls
                  </li>
                  <li>
                    Usage data details such as data about how you use our
                    website or web-based properties, pages viewed, etc.
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            <span className="subtitle">CONSENT</span>
            <br />
            <br />
            By using the Website/App and/ or by providing your information, you
            consent to the collection and use of the information you disclose on
            the Website/App in accordance with this Privacy Policy, including
            but not limited to your consent for sharing your information as per
            this privacy policy.
            <ul>
              <li>
                PURPOSES OF GATHERING YOUR PERSONAL DATA
                <br />
                We use your personal information to operate, provide, develop,
                and improve the products and services. These purposes include:
                <br />
                <br />
                <ul>
                  <li>
                    PURCHASE AND DELIVERY OF PRODUCTS AND SERVICES
                    <br />
                    We use your personal information to take and handle orders,
                    deliver products and services, process payments, and
                    communicate with you about orders, products and services,
                    and promotional offers.
                  </li>
                  <br />
                  <li>
                    PROVIDE, TROUBLESHOOT, AND IMPROVE Autoloop
                    <br />
                    We use your personal information to provide functionality,
                    analyze performance, fix errors, and improve the usability
                    and effectiveness of the Autoloop.
                  </li>
                  <br />
                  <li>
                    RECOMMENDATIONS AND PERSONALIZATION
                    <br />
                    We use your personal information to recommend features,
                    products, and services that might be of interest to you,
                    identify your preferences, and personalize your experience
                    with Autoloop.
                  </li>
                  <br />
                  <li>
                    COMMUNICATE WITH YOU
                    <br />
                    We use your personal information to communicate with you in
                    relation to Autoloop via different channels (e.g., by phone,
                    e-mail, chat).
                  </li>
                  <br />
                  <li>
                    ADVERTISING
                    <br />
                    We use your personal information to display interest-based
                    ads for features, products, and services that might be of
                    interest to you.
                  </li>
                  <br />
                </ul>
              </li>
            </ul>
            <br />
            <span className="subtitle">
              LAWFUL BASES OF PROCESSING YOUR PERSONAL DATA
            </span>
            <br />
            <br />
            We are permitted to process your Personal Data in compliance with
            applicable laws and regulations by relying on one or more of the
            following lawful bases:
            <ul>
              <li>
                You have explicitly agreed to us processing your Personal Data
                for a specific reason
              </li>
              <li>
                The processing is necessary to perform the agreement we have
                with you or to take steps to enter into an agreement with you
              </li>
              <li>
                The processing is necessary to be in compliance with our Legal
                Obligations
              </li>
              <li>
                The processing is necessary for the purposes of a legitimate
                interest (“Legitimate Interest”) pursued by us, such as:
                <ul>
                  <li>to provide services to you,</li>
                  <li>
                    to evaluate, develop or improve our products and services
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            Where the processing is based on your consent, you have a right to
            withdraw your consent at any time. You may withdraw consent by
            contacting us. Upon receipt of your written request to withdraw your
            consent, consequences of withdrawal will be communicated to you and,
            upon your agreement, your request for withdrawal will be processed.
            <br />
            <br />
            <span className="subtitle">
              COOKIES AND OTHER TRACKERS USED BY OUR DIGITAL PROPERTIES
            </span>
            <br />
            <br />
            Cookies are small text files that are placed on your computer by
            websites that you visit. Similarly, there are other types of
            trackers used. Likewise, Mobile Apps use requisite permissions and
            SDKs. These are used to make Websites &amp; Apps work, or work more
            efficiently, as well as to provide information to the owners of the
            website/ App.
            <br />
            <br />
            We use cookies, permissions and other trackers in our website,
            web-based properties and mobile applications that are used to
            collect and process data about you so we can provide you a better
            online experience as well as improve our services
            <br />
            <br />
            Below are the categories of cookies used on our website along with a
            description of what they are used for :
            <ul>
              <li>
                <span className="bold">[STRICTLY NECESSARY]</span> These cookies
                are needed to run our website, to keep it secure if you are
                logged on and to obey regulations that apply to us.
              </li>
              <li>
                <span className="bold">[STRICTLY NECESSARY]</span> If you are a
                customer, they help us know who you are so that you can log on
                and manage your accounts. They also help us keep your details
                safe and private.
              </li>
              <li>
                <span className="bold">[FUNCTIONAL]</span> These cookies are
                used for remembering things like:
                <ul>
                  <li>Your user ID on the log on page</li>
                  <li>Your region or country</li>
                  <li>Your preferred language</li>
                  <li>
                    Accessibility options like large font or high contrast pages
                  </li>
                </ul>
              </li>
              <li>
                <span className="bold">[PERFORMANCE]</span> These cookies tell
                us how you and our other customers use our website. We combine
                all this data together and study it. This helps us to:
                <ul>
                  <li>Improve the performance of our services</li>
                  <li>Improve the products we provide</li>
                </ul>
              </li>
            </ul>
            Most web browsers allow some control of most cookies through the
            browser settings. Please note disabling the{" "}
            <span className="bold">Strictly Necessary</span> cookies may cause
            certain parts of our website to remain inaccessible to you.
            <br />
            <br />
            We use other tracking mechanisms in addition to the above
            <ul>
              <li>Pixel tags</li>
              <li>Log Files</li>
              <li>Clickstream analytics</li>
            </ul>
            <br />
            <span className="bold">DO NOT TRACK</span>
            <br />
            <br />
            Some web browsers have a “Do Not Track” feature. This feature lets
            you tell websites you visit that you do not want to have your online
            activity tracked. These features are not yet uniform across
            browsers. Our sites are not currently set up to respond to those
            signals.
            <br />
            <br />
            <span className="bold">PERSONAL DATA DISCLOSURE</span>
            <br />
            <br />
            Autoloop app contains third party SDKs that collect personally
            identifiable information (PII). They use this information to better
            target users or provide elements of our products &amp; services on
            behalf of us.These third party sites have separate and independent
            privacy policies.
            <br />
            <br />
            <span className="bold">Autoloop USES 3 TYPES OF SDKs: </span>
            <br />
            <br />
            <table>
              <tr>
                <th>SDK</th>
                <th>Purpose</th>
              </tr>
              <tr>
                <td>Analytics</td>
                <td>
                  <ul>
                    <li>Settle errors</li>
                    <li>Better target users</li>
                    <li>
                      Provide push notifications and digital ads to the users
                    </li>
                    <li>Example-Clevertap,Appsflyer,Crashlytics, Gamooga</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Payment</td>
                <td>
                  Complete customers’ payment transaction Example – PayU,
                  Razorpay, Cashfree
                </td>
              </tr>
              <tr>
                <td>Login</td>
                <td>
                  Help users login at Autoloop Example – Google, Facebook.
                </td>
              </tr>
            </table>
            <br />
            We might also disclose your Personal Data to appropriate authorities
            if we believe that it is reasonably necessary to comply with a law,
            regulation, legal process, to protect the safety of any person, to
            address fraud, security, or technical issues, or to protect our
            rights or the rights of those who use our products &amp; services.
            <br />
            <br />
            <span className="bold">
              Reasons for disclosing your Personal Data to other parties:
            </span>
            <br />
            <br />
            We may disclose your Personal Data to others where it is lawful to
            do so including where we or they:
            <ul>
              <li>need to provide you with products or services</li>
              <li>where you have initiated a payment</li>
              <li>
                have a legal obligation to do so, e.g. to assist with detecting
                and preventing fraud,
              </li>
              <li>
                have requirement in connection with regulatory reporting,
                litigation or asserting or defending legal rights and interests
              </li>
              <li>have a legitimate business reason for doing so</li>
              <li>
                want to send you information and promotional material regarding
                our products and services,
              </li>
              <li>
                have asked you for your consent to share it, and you’ve agreed
              </li>
              <li>Improve your online experience for using our product.</li>
            </ul>
            <br />
            <span className="bold">
              To whom your Personal Data may be disclosed:
            </span>
            <br />
            <br />
            We may disclose your Personal Data for the above purposes to other
            parties including:
            <ul>
              <li>
                Other group companies and any subcontractors, agents or service
                providers who work for us or provide services to us or group
                companies (including their employees, sub-contractors, service
                providers, directors and officers)
              </li>
              <li>
                Law Enforcement Authorities, Government Authorities, courts,
                dispute resolution bodies, our regulators, auditors and any
                party appointed or requested by our regulators to carry out
                investigations or audits of our activities
              </li>
              <li>
                Statutory and regulatory bodies and authorities (including the
                government) investigating agencies and entities or persons, to
                whom or before whom it is mandatory to disclose the Personal
                Data as per the applicable law, courts, judicial and
                quasi-judicial authorities and tribunals, arbitrators and
                arbitration tribunals
              </li>
              <li>Overseas regulators</li>
              <li>
                Anybody else that we’ve been instructed to share your Personal
                Data with by you
              </li>
            </ul>
            <br />
            <span className="subtitle">CROSS-BORDER DATA TRANSFER</span>
            <br />
            <br />
            Personal Data we hold about you may be transferred to other
            countries outside your residential country for any of the purposes
            described in this Privacy Policy.
            <br />
            <br />
            You understand and accept that these countries may have differing
            (and potentially less stringent) laws relating to the degree of
            confidentiality afforded to the information it holds and that such
            information can become subject to the laws and disclosure
            requirements of such countries, including disclosure to governmental
            bodies, regulatory agencies and private persons, as a result of
            applicable governmental or regulatory inquiry, court order or other
            similar process. In addition, a number of countries have agreements
            with other countries providing for exchange of information for law
            enforcement, tax and other purposes.
            <br />
            <br />
            If we transfer your Personal Data to third parties for purposes
            stated in this Privacy Policy, we will use best endeavors to put in
            place appropriate controls and safeguards to ensure that your
            Personal Data is kept accurate, adequately protected, and processed
            only for specified and reasonable purposes in a manner that is fair,
            transparent and has a lawful basis, and is stored for no longer than
            is absolutely necessary.
            <br />
            <br />
            <span className="subtitle">DATA SECURITY</span>
            <br />
            <br />
            We are committed to protecting your Personal Data in our custody. We
            take reasonable steps to ensure appropriate physical, technical and
            managerial safeguards are in place to protect your Personal Data
            from unauthorized access, alteration, transmission and deletion. We
            work to protect the security of your personal information during
            transmission by using encryption protocols. We use multi-layered
            controls to help protect our infrastructure, constantly monitoring
            and improving our applications, systems, and processes to meet the
            growing demands and challenges of security.We ensure that the third
            parties who provide services to us under appropriate contracts, take
            appropriate security measures to protect your Personal Data in line
            with our policies.
            <br />
            <br />
            <span className="subtitle">RETENTION AND DISPOSAL OF DATA</span>
            <br />
            <br />
            We keep the Personal Data we collect about you on our systems or
            with third parties for as long as it is required for the purposes
            set out in this Privacy Policy and for legal or regulatory reasons.
            We will only use your Personal Data for those purposes and will make
            sure that your Privacy is protected. We shall take reasonable steps
            to delete or permanently de-identify Personal Data that is no longer
            needed.
            <br />
            <br />
            <span className="subtitle">LINKS TO OTHER WEBSITES</span>
            <br />
            <br />
            Our website may contain links to websites of other organisations.
            This privacy policy does not cover how that organisation processes
            Personal Data. We encourage you to read the privacy policies on the
            other websites you visit.
            <br />
            <br />
            <span className="subtitle">CHILDREN’S PRIVACY</span>
            <br />
            <br />
            Our website is directed to be used by adults only. If you are not an
            adult, while you may look at our site, you should not make a
            purchase, register, or submit Personal Data to us. We or our
            associates /affiliates do not knowingly collect information from
            minors.
            <br />
            <br />
            <span className="subtitle">YOUR RIGHTS AS A CUSTOMER</span>
            <br />
            <br />
            We understand that when you interact with Autoloop, you have rights
            over your Personal Data. These rights involve providing reasonable
            steps to allow you to access your personal data, correct any errors
            among others. In the event that you are not satisfied with our
            response or have unresolved concerns, you can get in touch with us
            to resolve the issue by means of{" "}
            <a href="emailto:privacy@biomye.com">privacy@biomye.com.</a>
            <br />
            <br />
            <span className="subtitle">CONTACT US</span>
            <br />
            <br />
            For any further queries and complaints related to privacy under
            applicable laws and regulations, you could reach us at:
            <br />
            Contact Email Address:{" "}
            <a href="mailto:support@biomye.com">support@biomye.com</a>
            <br />
            <br />
            <span className="bold">
              IN ACCORDANCE WITH INFORMATION TECHNOLOGY ACT 2000 AND RULES MADE
              THERE UNDER, CONSUMER PROTECTION ACT 2019 AND RULES MADE THERE
              UNDER
            </span>
            <br />
            <br />
            GRIEVANCE OFFICER – CUSTOMER SERVICES
            <br />
            Name : Mr. Ajay Singh
            <br />
            Email :{" "}
            <a href="mailto:grevianceofficer@biomye.com">
              grevianceofficer@biomye.com
            </a>
            <br />
            <br />
            <span className="bold">
              FOR LAW ENFORCEMENT AND COMPLIANCE UNDER CONSUMER PROTECTION ACT
              2019 AND RULES MADE THERE UNDER
            </span>
            <br />
            <br />
            NODAL OFFICER
            <br />
            Email :{" "}
            <a href="mailto:nodalofficer@biomye.com">nodalofficer@biomye.com</a>
            <br />
            <br />
            <span className="subtitle">NOTIFICATION OF CHANGES</span>
            <br />
            <br />
            We keep our Privacy Policy under regular review to make sure it is
            up to date and accurate. Any changes we may make to this Privacy
            Policy in the future will be posted on this page. We recommend that
            you re-visit this page regularly to check for any updates.
            <br />
            <br />
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
