import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Paper,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import "./fmcg.styles.scss";

const FMCG = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  let data = [
    {
      name: "About",
      image: process.env.PUBLIC_URL + "/assets/fmcg/about.png",
      content:
        "About is an integrated Healthcare brand with specialization in the hygiene product sphere. With our specialization in the Adult Diaper, Baby Diaper and Sanitary pads.",
    },
    {
      name: "Sight",
      image: process.env.PUBLIC_URL + "/assets/fmcg/sight.png",
      content:
        "Sight eye drops are an effective  eye formula to improve vision cleanse the eyeball lens and tear ducts from harmful debris and toxins and relieve eye strain from excessive computer screen radiation. Sight eye drops strengthen the capillaries and blood vessels of the eye and are highly effective to treat eye ailments surpassing even pharmaceutical drugs that often have detrimental side-effects such as permanent damage to the cornea and lens of the eye.",
    },
    {
      name: "Clear",
      image: process.env.PUBLIC_URL + "/assets/fmcg/clear.png",
      content:
        "Clear is perfect for all types of greasy stains on dishes and utensils, from food remnants to cooking oil. It can also be used to clean kitchen surfaces, such as tiled surfaces, sinks, basins, and other sanitary areas. Its characteristics are the mildness of its detergent base, its softness when cleaning, and a pleasant floral fragrance. It works fast to spread over the dishes, as it leaves a thick grease-cutting layer that makes it easy to wipe off greasy stains. A unique ingredient helps to prevent re-soiling. This biodegradable dishwashing liquid contains no phosphates. The liquid can be diluted with water at any concentration.",
    },
    {
      name: "4Sure",
      image: process.env.PUBLIC_URL + "/assets/fmcg/4sure.png",
      content:
        "4Sure is a complete intimate health and women health support brand, it focuses on the intimate health issues, Contraception and Pregnancy test Kits. An all arounder in taking  care of women health over time.",
    },
    {
      name: "AQUA",
      image: process.env.PUBLIC_URL + "/assets/fmcg/aqua.png",
      content:
        "AQUA is an established detergent and fabric care brand with its low foam high cleaning formula specially researched and developed by the team for the front load automatic washing machines. Protecting the machine from internal damages and rejuvenating the clothes strengthen the fabric. ",
    },
    {
      name: "Brown Coffee",
      image: process.env.PUBLIC_URL + "/assets/fmcg/brown.png",
      content:
        "Coffee is beyond a product; it is a culture rooted deep in humans’ DNA; in our opinion, it is the spirit and blessing of those in the coffee chain who make this delicious beverage available to the 65% of the World population that frequently enjoys coffee. We at Brown Coffee make your every sip memorable.",
    },
    {
      name: "Brethol",
      image: process.env.PUBLIC_URL + "/assets/fmcg/brethol.png",
      content:
        "Discover our range of Nasal Sprays, Drops and Lozenges to relieve congestion caused by colds, flu and allergies. Products suitable for Adults and Children. All-natural formulation ensures no side effects of the brand ensuring consumer satisfaction and promoting a chemical free environment.",
    },
    {
      name: "Dilbar Tea",
      image: process.env.PUBLIC_URL + "/assets/fmcg/dilbar.png",
      content:
        "Dilbar Tea goes beyond being just a cup of tea - it is a fresh start to a new day for People. The distinctive Taste of our classical blend takes your daily tea to the next level. What’s better than a freshly brewed cup of tea?",
    },
    {
      name: "Hunter",
      image: process.env.PUBLIC_URL + "/assets/fmcg/hunter.png",
      content:
        "Hunter Muscle Performance provides unparalleled products, knowledge, and service to health-conscious athletes all over the world. Over the years, the product line has grown from fuels & supplements to now include clothing, body care products and more.",
    },
    {
      name: "Floral Essence",
      image: process.env.PUBLIC_URL + "/assets/fmcg/floral.png",
      content:
        "Floral Essences, when nature meets luxury, when body unites with the soul. While based on ancient recipes, our research and development team and labs are constantly focused on the future. This allows us to stay ahead of the latest developments. Our R&D team of chemists and pharmacists are constantly improving maximum natural formulations, cold extracting and mixing processes, for the best input possible into our products and skin care applications. We aim to offer the most beneficial solutions that fit all of your needs; thus, Improved products and innovative applications quickly find their way to you.",
    },
    {
      name: "Cresme Cosmetics",
      image: process.env.PUBLIC_URL + "/assets/fmcg/creseme.png",
      content:
        "We draw our inspiration from skin’s health and vitality. Definition of Beauty is a radiant complexion which reflects two basic factors: skin’s health and a sustainable approach to life. Our products are made with minerals using a blend of pure botanical extracts combined into unique formulations perfectly harmonized with the skin, rejuvenating the skin and promoting hair growth",
    },
    {
      name: "ONLY Test",
      image: process.env.PUBLIC_URL + "/assets/fmcg/only.png",
      content:
        "Only Pregnancy and Ovulation Tests, ONLY tests can assist you with finding the responses you are searching for by furnishing clear outcomes with precision and comfort. Our ongoing scope of items incorporates pregnancy tests and ovulation tests. Welcome your pregnancy with us, a quality product that you can rely on. ",
    },
  ];

  return (
    <div className="fmcg">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="link" to="/csr-sustainability">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="link" to="/innovation-rnd">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div>
            {data.map((c) => (
              <Paper className="item">
                <Grid container spacing={0} justify="center">
                  <Grid item xs={3} className="left-item">
                    <img className="prod-img" alt="product" src={c.image} />
                  </Grid>
                  <Grid item xs={9} className="right-item">
                    <div className="prod-text">
                      <div className="title">{c.name}</div>
                      <div className="content-text">{c.content}</div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">FMCG</div>
          <div>
            {data.map((c) => (
              <div className="item">
                <img className="prod-img" alt="product" src={c.image} />
                <div className="title">{c.name}</div>
                <div className="content-text">{c.content}</div>
              </div>
            ))}
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FMCG;
