import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Paper,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import "./ayurveda.styles.scss";

import pdf from "./ayur.pdf";

const Ayurveda = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  let data = [
    {
      name: "Biomye Botanicals",
      image: process.env.PUBLIC_URL + "/assets/ayurved/botanicals.png",
      content:
        "A Edible centric brand that focuses on development of gummies with the merger of the pharma tech, the brand is a well known figure across Eastern Europe, the new age medicinal gummies solve the health without the need of taking pills.",
    },
    {
      name: "Sangam Herbals",
      image: process.env.PUBLIC_URL + "/assets/ayurved/sangam.png",
      content:
        'Sangam Herbals , which means "Merger of Herbs" in translation . All presented products are our personal development based on the ancient prescriptions of Ayurveda, and each recipe is made “with soul”. All these years of work have been based on only one principle - do for others as we would do for ourselves. Products from Sangam Herbals are based on natural organic raw materials , do not contain sulfates and parabens. Synthetic dyes, artificial thickeners and fillers are not used. We tried to preserve the strength and natural healing properties of herbs as much as possible.',
    },
    {
      name: "SVH ShriVeda Herbals",
      image: process.env.PUBLIC_URL + "/assets/ayurved/shriveda.png",
      content:
        "A Young Brand focused on the complex formulation of ayurvedic and nutraceutical OTC “Food supplement”, with the world class labs and Research and Development facility and certified in “KOSHER PRODUCTS” and “HALAL PRODUCTS”. The compliance is fulfilled with fulfilling all the requirement for the release of the brand is strictly monitored with the pharma background team.",
    },
    {
      name: "Sita Ayurveda",
      image: process.env.PUBLIC_URL + "/assets/ayurved/sita.png",
      content:
        "Established in 2019, is a well known brand of high quality Ayurvedic Juices, Herbal capsules and tablets with the given potency of 500mg the 3:1 herb to expecient ratio used in the manufacturing results in high quality GMP and Pharma Grade equipment gives the brand a leap forward from its competitors, Focusing only on classical formulation given in the books and merging it with high quality Sita Ayurveda has let the presence felt in the market.",
    },
  ];

  return (
    <div className="ayurveda">
      <div className="web">
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Link className="first" to="/">
              <Button className="btn">Home</Button>
            </Link>
            <Link className="selected">
              <Button className="btn">Our Businesses</Button>
            </Link>
            <Link className="link" to="/csr-sustainability">
              <Button className="btn">CSR & Sustainability</Button>
            </Link>
            <Link className="link" to="/innovation-rnd">
              <Button className="btn">Innovation and R&D</Button>
            </Link>
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <Paper className="text">
            Ayurveda, a characteristic arrangement of medication, started in
            India over a long time back. The term Ayurveda is gotten from the
            Sanskrit words ayur (life) and veda (science or information). Hence,
            Ayurveda means information on life. In view of the possibility that
            sickness is because of a lopsidedness or stress in an individual's
            cognizance, Ayurveda energizes specific way of life mediations and
            normal treatments to recover a harmony between the body, brain,
            soul, and the climate. Ayurveda treatment begins with an interior
            purging interaction, trailed by an exceptional eating regimen,
            natural cures, knead treatment, yoga, and contemplation.
            <br />
            The ideas of widespread interconnectedness, the body's constitution
            (prakriti), and life powers (doshas) are the essential premise of
            ayurvedic medication. Objectives of treatment help the individual by
            dispensing with debasements, decreasing side effects, expanding
            protection from illness, diminishing concern, and expanding
            agreement throughout everyday life. Spices and different plants,
            including oils and normal flavors, are utilized widely in Ayurvedic
            treatment.
            <br />
            <br />
            In India, Ayurveda is viewed as a type of clinical consideration,
            equivalent to customary Western medication, conventional Chinese
            medication, naturopathic medication, and homeopathic medication.
            <br />
            Download the full list of{" "}
            <a href={pdf} download="Ayurveda&Traditional.pdf">
              products
            </a>{" "}
            here.
          </Paper>
          <div>
            {data.map((c) => (
              <Paper className="item">
                <Grid container spacing={0} justify="center">
                  <Grid item xs={3} className="left-item">
                    <img className="prod-img" alt="product" src={c.image} />
                  </Grid>
                  <Grid item xs={9} className="right-item">
                    <div className="prod-text">
                      <div className="title">{c.name}</div>
                      <div className="content-text">{c.content}</div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved
          </div>
          <div className="options">
            <Link className="link-cover" to="/careers">
              <div className="option-item">Careers / Contact Us</div>
            </Link>
            <Link className="link-cover" to="/tnc">
              <div className="option-item">Terms & Conditions</div>
            </Link>
            <Link className="link-cover" to="/privacy">
              <div className="option-item">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile">
        {/* Drawer */}
        <Drawer
          className="drawer"
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <List>
            {[
              "Home",
              "Our Businesses",
              "CSR & Sustainability",
              "Innovation and R&D",
              "Careers / Contact Us",
              "Terms & Conditions",
              "Privacy Policy",
            ].map((text, _) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (text === "Home") navigate("/");
                    else if (text === "Our Businesses") navigate("/business");
                    else if (text === "CSR & Sustainability")
                      navigate("/csr-sustainability");
                    else if (text === "Innovation and R&D")
                      navigate("/innovation-rnd");
                    else if (text === "Careers / Contact Us")
                      navigate("/careers");
                    else if (text === "Terms & Conditions") navigate("/tnc");
                    else if (text === "Privacy Policy") navigate("/privacy");
                  }}
                >
                  <ListItemText
                    primary={text}
                    style={{
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {/* Top Section */}
        <AppBar elevation={0} position="static" className="appbar">
          <Toolbar variant="dense" className="toolbar">
            <img
              alt="logo"
              className="logo"
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
            />
            <Menu className="menu-icon" onClick={() => setOpenDrawer(true)} />
          </Toolbar>
        </AppBar>
        {/* Middle Section */}
        <div className="content">
          <div className="title">Ayurveda & Traditional Medicines</div>
          <div className="text">
            Ayurveda, a characteristic arrangement of medication, started in
            India over a long time back. The term Ayurveda is gotten from the
            Sanskrit words ayur (life) and veda (science or information). Hence,
            Ayurveda means information on life. In view of the possibility that
            sickness is because of a lopsidedness or stress in an individual's
            cognizance, Ayurveda energizes specific way of life mediations and
            normal treatments to recover a harmony between the body, brain,
            soul, and the climate. Ayurveda treatment begins with an interior
            purging interaction, trailed by an exceptional eating regimen,
            natural cures, knead treatment, yoga, and contemplation.
            <br />
            The ideas of widespread interconnectedness, the body's constitution
            (prakriti), and life powers (doshas) are the essential premise of
            ayurvedic medication. Objectives of treatment help the individual by
            dispensing with debasements, decreasing side effects, expanding
            protection from illness, diminishing concern, and expanding
            agreement throughout everyday life. Spices and different plants,
            including oils and normal flavors, are utilized widely in Ayurvedic
            treatment.
            <br />
            <br />
            In India, Ayurveda is viewed as a type of clinical consideration,
            equivalent to customary Western medication, conventional Chinese
            medication, naturopathic medication, and homeopathic medication.
            <br />
            Download the full list of{" "}
            <a href={pdf} download="Ayurveda&Traditional.pdf">
              products
            </a>{" "}
            here.
          </div>
          <div>
            {data.map((c) => (
              <div className="item">
                <img className="prod-img" alt="product" src={c.image} />
                <div className="title">{c.name}</div>
                <div className="content-text">{c.content}</div>
              </div>
            ))}
          </div>
        </div>
        {/* Bottom Section */}
        <div className="footer">
          <div className="copyright">
            Abiomye Industries Pvt. Ltd. | All rights reserved{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ayurveda;
